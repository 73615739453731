<template>
    <header class="page-title">
        <h1><i class="bi bi-people-fill"></i> 稼働明細</h1>
    </header>

    <section class="section">
        <form class="row align-items-end mb-3" @submit.prevent="search()">
            <div class="col-6">
                <label class="form-label">期間</label>
                <div class="input-group">
                    <form-input-month v-model="month_from" required @validlyChange="fetchMaster()"></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required @validlyChange="fetchMaster()"></form-input-month>
                </div>
            </div>
            <div class="col-6">
                <label class="form-label">案件</label>
                <form-select-search v-model="project_id" :options="projects" option_value="project_id" option_label="project_name" :disabled="projects.length === 0"></form-select-search>
            </div>
            <div class="col-4">
                <label class="form-label">ユーザ</label>
                <form-select-search v-model="user_id" :options="users" option_value="user_id" option_label="user_name" :disabled="users.length === 0"></form-select-search>
            </div>
            <div class="col-6">
                <label class="form-label">作業内容</label>
                <form-input v-model="work_note" class="flex-grow-1"></form-input>
            </div>
            <div class="col-2">
                <button type="submit" class="btn btn-primary ms-3">表示</button>
            </div>
            <div class="mt-3 text-end" style="text-align: right;">
                <span v-if="$store.getters['auth/canExportCsv']()" class="dropdown ms-3">
                    <button class="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i class="bi bi-download"></i> CSV
                    </button>
                    <ul class="dropdown-menu">
                        <li><button class="dropdown-item" type="button" @click="exportCsv()">CSVエクスポート</button></li>
                        <li><router-link :to="{name: 'Export'}" class="dropdown-item">エクスポート履歴</router-link></li>
                    </ul>
                </span>
                <button v-else type="button" class="btn btn-outline-primary ms-3" disabled><i class="bi bi-download"></i> CSV</button>
                <button type="button" @click="print()" class="btn btn-outline-primary ms-3" :disabled="project_id === null">印刷</button>
                <button type="button" @click="show_bulk_edit = true" class="btn btn-outline-primary ms-3" :disabled="checked_work_ids.length === 0">一括変更</button>
            </div>
        </form>

        <table class="table table-bordered mb-3">
            <thead>
                <tr>
                    <th class="p-0"><label class="d-block p-3 text-center"><input type="checkbox" v-model="checked_all"></label></th>
                    <th>日付</th>
                    <th>案件</th>
                    <th>作業者</th>
                    <th>作業時間</th>
                    <th>請求時間</th>
                    <th>フェーズ/カテゴリ</th>
                    <th>作業内容</th>
                </tr>
            </thead>
            <tbody v-if="loading">
                <tr v-for="i in Array(10)" :key="i">
                    <td class="p-0"><label class="d-block p-3 text-center"><input type="checkbox" disabled></label></td>
                    <td><text-loader :width="6"></text-loader></td>
                    <td><text-loader :width="10"></text-loader></td>
                    <td><text-loader :width="6"></text-loader></td>
                    <td class="text-center"><text-loader :width="4"></text-loader></td>
                    <td class="text-center"><text-loader :width="4"></text-loader></td>
                    <td><text-loader :width="10"></text-loader></td>
                    <td><text-loader :width="10"></text-loader></td>
                    <td>
                        <button disabled class="btn btn-sm btn-primary"><i class="bi bi-pencil-square"></i></button>
                        <button disabled class="btn btn-sm btn-light ms-3"><i class="bi bi-journal"></i></button>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="work of works" :key="work.work_id">
                    <td class="p-0"><label class="d-block p-3 text-center"><input type="checkbox" :value="work.work_id" v-model="checked_work_ids"></label></td>
                    <td class="nowrap">{{ $helper.ymdw(work.work_date)  }}</td>
                    <td>{{ work.project?.project_name }}</td>
                    <td>{{ work.user?.user_name }}</td>
                    <td class="nowrap text-center">{{ $helper.clock(work.work_time) }}</td>
                    <td class="nowrap text-center" :class="{'text-danger': (work.charge_time !== null && work.charge_time !== work.work_time)}">{{ $helper.clock(work.charge_time) }}</td>
                    <td>{{ work.phase?.phase_name }}{{ work.work_category?.category_name }}</td>
                    <td>{{ work.work_note }}</td>
                    <td class="nowrap">
                        <button @click="editReady(work)" class="btn btn-sm btn-primary"><i class="bi bi-pencil-square"></i></button>
                        <router-link :to="{name: 'ReportEdit', params: {report: work.report_id}}" class="btn btn-sm btn-light ms-3"><i class="bi bi-journal"></i></router-link>
                    </td>
                </tr>
            </tbody>
            <tfoot v-if="paginator && paginator.last_page === 1">
                <tr>
                    <th>合計</th>
                    <td colspan="3"></td>
                    <td class="text-center">{{ $helper.clock(work_time_total) }}</td>
                    <td class="text-center">{{ Number.isNaN(charge_time_total) ? '-' : $helper.clock(charge_time_total) }}</td>
                    <td colspan="3"></td>
                </tr>
            </tfoot>
        </table>

        <paginator :meta="paginator" @move="search" v-if="paginator"></paginator>
    </section>

    <div class="offcanvas offcanvas-end show" style="visibility: visible;" v-if="work_editing">
        <div class="offcanvas-header">
            <h3 class="offcanvas-title">{{ work_editing.user?.user_name }} さん - {{ $helper.ymdw(work_editing.work_date) }}</h3>
            <button type="button" class="btn-close text-reset" @click="work_editing = null"></button>
        </div>
        <div class="offcanvas-body">
            <form @submit.prevent="edit()">
                <div class="mb-3">
                    <label>案件</label>
                    <form-select-object-search v-model="work_editing.project" :options="projects" option_value="project_id" option_label="project_name" required :disabled="projects.length === 0"></form-select-object-search>
                </div>
                <div class="mb-3">
                    <label>作業時間</label>
                    <form-input-number v-model="work_editing.work_time" :step="0.25" required></form-input-number>
                </div>
                <div class="mb-3">
                    <label>請求時間</label>
                    <form-input-number v-model="work_editing.charge_time" :step="0.25" :disabled="!work_editing.project || work_editing.project.project_type !== ProjectType.TIME_AND_MATERIAL"></form-input-number>
                </div>
                <div class="mb-3">
                    <label v-if="work_editing.project && work_editing.project.project_type === ProjectType.ONE_OFF">フェーズ</label>
                    <label v-else>カテゴリ</label>
                    <select v-if="!work_editing.project" class="form-select" disabled></select>
                    <form-select-object v-else-if="work_editing.project.project_type === ProjectType.ONE_OFF" v-model="work_editing.phase" :options="work_editing.project.phases" option_value="phase_id" option_label="phase_name" empty_option="-- フェーズ --" required></form-select-object>
                    <form-select-object v-else v-model="work_editing.work_category" :options="work_editing.project.work_categories" option_value="work_category_id" option_label="category_name" empty_option="-- 作業カテゴリ --" required></form-select-object>
                </div>
                <div class="mb-3">
                    <label>作業内容</label>
                    <form-input v-model="work_editing.work_note" required></form-input>
                </div>
                <div class="mb-3">
                    <button type="submit" class="btn btn-info">更新</button>
                </div>
            </form>
        </div>
    </div>
    <div class="offcanvas-backdrop fade show" v-if="work_editing" @click="work_editing = null"></div>

    <div class="offcanvas offcanvas-end show" style="visibility: visible;" v-if="show_bulk_edit">
        <div class="offcanvas-header">
            <h3 class="offcanvas-title">一括編集 ({{ checked_work_ids.length }} 件)</h3>
            <button type="button" class="btn-close text-reset" @click="show_bulk_edit = false"></button>
        </div>
        <div class="offcanvas-body">
            <form @submit.prevent="bulk_edit()">
                <div class="mb-3">
                    <label>案件</label>
                    <form-select-object-search v-model="bulk_edit_param.project" :options="projects" option_value="project_id" option_label="project_name" required :disabled="projects.length === 0"></form-select-object-search>
                </div>
                <div class="mb-3">
                    <label v-if="bulk_edit_param.project && bulk_edit_param.project.project_type === ProjectType.ONE_OFF">フェーズ</label>
                    <label v-else>カテゴリ</label>
                    <form-select-object v-if="bulk_edit_param.project && bulk_edit_param.project.project_type === ProjectType.ONE_OFF" v-model="bulk_edit_param.phase" :options="bulk_edit_param.project.phases" option_value="phase_id" option_label="phase_name" empty_option="-- フェーズ --" required></form-select-object>
                    <form-select-object v-else-if="bulk_edit_param.project" v-model="bulk_edit_param.work_category" :options="bulk_edit_param.project.work_categories" option_value="work_category_id" option_label="category_name" empty_option="-- 作業カテゴリ --" required></form-select-object>
                    <select v-else class="form-select" disabled></select>
                </div>
                <div class="mb-3">
                    <button type="submit" class="btn btn-info">更新</button>
                </div>
            </form>
        </div>
    </div>
    <div class="offcanvas-backdrop fade show" v-if="show_bulk_edit" @click="show_bulk_edit = false"></div>
</template>

<script>
import FormInput from '@/components/forms/FormInput';
import FormInputMonth from '@/components/forms/FormInputMonth';
import FormInputNumber from '@/components/forms/FormInputNumber';
import FormSelectObject from '@/components/forms/FormSelectObject';
import FormSelectObjectSearch from '@/components/forms/FormSelectObjectSearch';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import Paginator from '@/components/tools/Paginator';
import TextLoader from '@/components/tools/TextLoader';
import Work from "@/models/entities/work";
import User from "@/models/entities/user";
import Project from "@/models/entities/project";
import ProjectType from "@/models/enums/project-type";

export default {
    name: 'WorkDetail',
    components: {
        FormInput,
        FormInputMonth,
        FormInputNumber,
        FormSelectObject,
        FormSelectObjectSearch,
        FormSelectSearch,
        Paginator,
        TextLoader,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            project_id: null,
            user_id: null,
            loading: true,
            works: [],
            paginator: null,
            users: [],
            projects: [],
            work_editing: null, // 更新画面表示用
            checked_work_ids: [],
            show_bulk_edit: false,
            bulk_edit_param: {
                project: null,
                phase: null,
                work_category: null,
            },
            ProjectType,
        }
    },
    mounted() {
        this.month_from = this.$route.query.month_from ? parseInt(this.$route.query.month_from) : null;
        this.month_to = this.$route.query.month_to ? parseInt(this.$route.query.month_to) : null;
        this.project_id = this.$route.query.project ? parseInt(this.$route.query.project) : null;
        this.user_id = this.$route.query.user ? parseInt(this.$route.query.user) : null;

        this.fetchMaster();
        this.search();
    },
    methods: {
        fetchMaster()
        {
            this.$http.get('/list/user', {params: {month_from: this.month_from, month_to: this.month_to}})
            .then((response) => {
                this.users = response.data.map((row) => {return new User(row)});
            });
            this.$http.get('/list/project', {params: {month_from: this.month_from, month_to: this.month_to}})
            .then((response) => {
                this.projects = response.data.map((row) => {return new Project(row)});
            });
        },
        search(page = 1) {
            this.works.splice(0);
            this.paginator = null;
            this.loading = true;
            this.$http.get('/work', {params: {
                month_from: this.month_from,
                month_to: this.month_to,
                user_id: this.user_id,
                project_id: this.project_id,
                page: page,
                work_note: this.work_note,
            }})
            .then((response) => {
                this.works = response.data.data.map((row) => {return new Work(row)});
                this.paginator = response.data.meta;
            })
            .finally(() => {
                this.loading = false;
            });
        },
        editReady(work) {
            // 保存するまで一覧に反映しないため、複製する
            this.work_editing = new Work(work);
            // リレーション (phases/categories) を使うため入れ直す
            this.work_editing.project = this.projects.find(row => row.project_id === work.project.project_id);
        },
        edit() {
            this.startScreenLoading();
            this.$http.put(`/work/${this.work_editing.work_id}`, this.work_editing)
            .then((response) => {
                // 一覧に反映
                let index = this.works.findIndex((row) => row.work_id === this.work_editing.work_id);
                this.works[index] = new Work(response.data)
                this.work_editing = null;
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        bulk_edit() {
            this.startScreenLoading();
            this.bulk_edit_param.work_ids = this.checked_work_ids;
            if (this.bulk_edit_param.project.project_type === ProjectType.ONE_OFF) {
                this.bulk_edit_param.work_category = null;
            } else {
                this.bulk_edit_param.phase = null;
            }

            this.$http.post('/work/bulk-update', this.bulk_edit_param)
            .then(() => {
                this.show_bulk_edit = false;
                this.checked_work_ids = [];
                // 一覧再取得
                this.search();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        print() {
            let uri = process.env.VUE_APP_API_URI + '/file/work-report'
                    + '?month_from=' + (this.month_from ?? '')
                    + '&month_to=' + (this.month_to ?? '')
                    + '&project_id=' + (this.project_id ?? '')
                    + '&user_id=' + (this.user_id ?? '')
                    + '&access_token=' + this.$store.state.auth.access_token;
            window.open(uri);
        },
        exportCsv() {
            this.startScreenLoading();

            this.$http.post('/work/export', {
                month_from: this.month_from,
                month_to: this.month_to,
                user_id: this.user_id,
                project_id: this.project_id,
            })
            .then(() => {
                this.$router.push({name: 'Export'});
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    },
    computed: {
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        work_time_total() {
            return this.works.reduce((sum, work) => sum + parseFloat(work.work_time), 0);
        },
        charge_time_total() {
            return this.works.reduce((sum, work) => sum + parseFloat(work.charge_time), 0);
        },
        checked_all: {
            get() {
                return this.works.length === this.checked_work_ids.length;
            },
            set(value) {
                if (value) {
                    this.checked_work_ids = this.works.map((work) => work.work_id);
                } else {
                    this.checked_work_ids = [];
                }
            },
        }
    },
}
</script>

<style scoped>
</style>
